// https://github.com/jquense/yup
import * as yup from 'yup'


export default {
    name: 'yupMixin',
    data () {
        return {
            // errors: [],
        }
    },
    computed: {
        // schema () {},
        // validateData () {},
        $yup () {
            return yup
        },
    },
    methods: {
        //   async  validate ({ abortEarly = false }) {
        //       try {
        //           if (!this.schema) return true
        //           await this.schema.validate(this.validateData, { abortEarly })
        //           return true
        //       } catch (error) {
        //           this.errors = error.errors
        //           throw error
        //       }
        //   },

    },
}
