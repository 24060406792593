<template>
  <div class="my-stepper-import--form">
    <v-row class="my-stepper-import--form-header title-m-bold">
      <v-col>Настройки импорта</v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaseDialogFieldBlock
          title="Генерация событий"
          description="Выберите требуется ли начисление приветственных бонусов и запуск настроенных активностей для импортируемых клиентов"
        >
          <base-ext-switch
            v-model="event_enable"
            label="генерировать события по каждому клиенту"
          />
        </BaseDialogFieldBlock>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaseDialogFieldBlock
          title="Соответствие колонок"
        >
          <template v-slot:description>
            Выберите в таблице ниже к кому типу отнести импортируемые данные.<br>
            Обязательное поле: телефон.
          </template>
          <div class="my-stepper-import--form-status">
            <div
              class="body-m-regular"
              style="font-weight: 600;"
            >
              Предварительно загружено: {{ excel.length }} {{ declOfNum(excel.length, rowStr) }}
            </div>
            <div
              v-if="errors.length"
              class="body-m-regular my-stepper-import--form-status-error-item my-stepper-import--form-status-error"
              @click="toErrorView('error')"
            >
              <v-icon>$iconify_bx-bxs-error-alt</v-icon>
              <span
                class="my-stepper-import--form-status-error-text"
              >{{ errors.length }} {{ declOfNum(errors.length, errorStr) }}
              </span>
            </div>
            <div
              v-if="warnings.length"
              class="body-m-regular my-stepper-import--form-status-error-item my-stepper-import--form-status-warning"
              @click="toErrorView('warning')"
            >
              <v-icon>$iconify_bx-bxs-error</v-icon>
              <span
                class="my-stepper-import--form-status-error-text"
              >{{ warnings.length }} {{ declOfNum(warnings.length, warningStr) }}</span>
            </div>
          </div>
          <div class="my-stepper-import--form-content">
            <div class="my-stepper-import--step-two">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="(item, index) in colsMap"
                        :key="`header-${index}`"
                        class="text-left"
                      >
                        <div>{{ item.sourceField === '__rowNum__' ? '№' : item.sourceField }}</div>
                        <div class="my-stepper-import--step-two-select">
                          <v-select
                            v-if="item.sourceField !== '__rowNum__'"
                            v-model="item.targetField"
                            :items="types"
                            placeholder="Выберите тип ячейки"
                            outlined
                            hide-details
                            clearable
                            @change="onChangeCols(index)"
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, i) in rows"
                      :key="`row-${i}`"
                    >
                      <td
                        v-for="(cell, j) in row"
                        :key="`cell-${i}${j}`"
                      >
                        {{ cell }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </BaseDialogFieldBlock>
      </v-col>
    </v-row>
    <v-row
      class="my-stepper-import--form-action"
      justify="space-between"
    >
      <v-col cols="auto">
        <v-btn
          color="secondary"
          @click="back"
        >
          <iconify-icon
            icon="arrow-back"
            width="21"
            style="margin-right: 5px;"
          />
          Назад
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="primary"
          :disabled="!isValidExcel"
          :loading="loading"
          @click="upload()"
        >
          <iconify-icon
            icon="clarity-import-line"
            width="21"
            style="margin-right: 5px;"
          />
          Загрузить базу
        </v-btn>
      </v-col>
    </v-row>
    <error-view
      v-if="dialogError"
      :dialog.sync="dialogError"
      :errors="viewErr"
      :type="viewType"
    />
  </div>
</template>

<script>
  import convertor from '@/mixins/convertor'
  import ErrorView from '@/views/crm/client/import/ErrorView'
  import { mapGetters, mapActions } from 'vuex'
  import yupMixin from '@/mixins/yupMixin'

  export default {
    components: {
      ErrorView,
    },
    mixins: [convertor, yupMixin],
    props: {
      step: {
        type: Number,
        default: 2,
      },
      excel: {
        type: Array,
        default: () => ([]),
      },
    },
    data () {
      return {
        valid: false,
        loading: false,
        maxCol: 8,
        colsMap: [], // колонки excel по типам
        viewErr: [], // просмотр ошибок
        viewType: null,
        rowStr: ['строка', 'строки', 'строк'],
        errorStr: ['ошибка', 'ошибки', 'ошибок'],
        warningStr: ['предупреждение', 'предупреждения', 'предупреждений'],
        dialogError: false,
        event_enable: true,
        GetFieldsAction: false,
      }
    },

    computed: {
      ...mapGetters({
        programId: 'programId',
      }),
      attributes () {
        return Object.fromEntries(this.types.map(x => [x.value, x.text]))
      },
      isValidExcel () {
        return this.errors.length === 0
      },
      errors () {
        return (!this.targetFields.some(x => x.value === 'phone'))
          ? ['Выберите колонку с телефоном']
          : this.$yup.object({
            phone: this.$yup.string().required().isPhone(),
          }).fails(this.selectedExcel)
      },
      targetFields () {
        return this.types.filter(x => this.colsMap.some(map => map.targetField === x.value))
      },
      warnSchema () {
        return this.$yup.object(Object.fromEntries(this.targetFields.filter(x => x.value !== 'phone').map(x => [x.value, x.rules])))
      },
      warnings () {
        return this.warnSchema.fails(this.selectedExcel)
      },
      program () {
        return this.$store.getters['company/program/program']
      },
      programSettingsFields () {
        return this.$store.getters['settings/account-fields/fields']?.[this.programId] || []
      },
      types () {
        const typeRules = {
          integer: this.$yup.number().integer(),
          float: this.$yup.number(),
          date: this.$yup.string().isDate(),
          datetime: this.$yup.string().isDate(),
          boolean: this.$yup.boolean(),
        }

        return [
          { value: 'phone', text: 'Телефон', required: true, rules: this.$yup.string().required().isPhone() },
          { value: 'email', text: 'Email', required: false, rules: this.$yup.string().notRequired().email() },
          { value: 'name', text: 'Имя', required: false, rules: this.$yup.string().notRequired().max(255) },
          { value: 'lastname', text: 'Фамилия', required: false, rules: this.$yup.string().notRequired().max(255) },
          { value: 'middlename', text: 'Отчество', required: false, rules: this.$yup.string().notRequired().max(255) },
          { value: 'birthday', text: 'Дата рождения', required: false, rules: this.$yup.string().nullable().isDate() },
          { value: 'balance', text: 'Баланс', required: false, rules: this.$yup.number().notRequired().min(0) },
          {
            value: 'gender',
            text: 'Пол',
            required: false,
            rules: this.$yup.string().notRequired().oneOf(['m', 'f'], 'Значение пола должно быть "m" или "f"'),
          },
          { value: 'consent_sms', text: 'Согласие на СМС', required: false, rules: this.$yup.boolean().nullable() },
          { value: 'consent_advertising', text: 'Согласие на рекламу', required: false, rules: this.$yup.boolean().nullable() },
          { value: 'comment', text: 'Комментарий', required: false, rules: this.$yup.string().notRequired().max(4096) },
          { value: 'card_code', text: 'Код карты', required: false, rules: this.$yup.string().notRequired().max(255) }
        ].concat(this.programSettingsFields.map(item => {
          let rules = typeRules[item.type] || this.$yup.string().max(1000)

          rules = rules.nullable() // .typeError(`Поле ${item.name} имеет ошибочный тип`).label(item.name)

          return {
            value: `field_${item.id}`,
            text: item.name,
            required: false,
            rules: rules,
          }
        })).map((item) => {
          item.rules = item.rules.typeError(`Поле ${item.text} имеет ошибочный тип`).label(item.text)
          return item
        })
      },
      selectedExcel () {
        return this.excel.map(item => {
          const importRow = {}
          this.colsMap.filter(map => !!map.targetField).forEach((map, index) => {
            importRow[map.targetField] = item[map.sourceField]
          })
          console.log(item, importRow)
          return importRow
        })
      },
      rows () {
        if (this.excel.length) {
          return this.excel.slice(0, 5)
        }
        return []
      },
    },
    watch: {
      programId () {
        this.init()
      },
    },
    methods: {
      ...mapActions({
        GetFields: 'settings/account-fields/GetFields',
        uploadImportChunks: 'crm/client/uploadImportChunks'
      }),
      back () {
        this.$emit('update:step', 1)
      },
      onChangeCols (index) {
        console.log('onChangeCols', index)
        const col = this.colsMap[index]
        this.colsMap.forEach((item, i) => {
          if (item.targetField === col.targetField && index !== i) {
            item.targetField = null
          }
        })
      },
      toErrorView (type) {
        if (type === 'error') {
          this.viewErr = this.errors
        }
        if (type === 'warning') {
          this.viewErr = this.warnings
        }
        this.viewType = type
        this.dialogError = true
      },

      async upload () {          
          this.loading = true;

          if (this.step !== 3) this.$emit('update:step', 3)
          
          try {
            await this.uploadImportChunks({ 
              items: this.selectedExcel, 
              programId: this.program.id, 
              eventEnable: this.event_enable
            })
          } catch (e) {
            console.log(e)
          }

          this.loading = false;      
          
      },
      async init () {
        try {
          this.colsMap = this.excel && this.excel.length ? Object.keys(this.excel[0]).map(item => {
            return {
              sourceField: item,
              targetField: null,
            }
          }) : []

          this.GetFieldsAction = true
          await this.GetFields({ id: this.programId })
        } catch (error) {
          console.log(error)
        } finally {
          this.GetFieldsAction = false
        }
      },
    },
    created () {
      this.init()
    },
  }
</script>

<style lang="scss" scoped>

</style>
