<template>
  <div class="my-stepper-import--form">
    <v-row class="d-flex align-center">
      <v-col>
        <div class="my-stepper-import--form-header title-m-bold">
          Результат импорта
        </div>
      </v-col>
      <v-col class="text-right">
        <v-btn
              color="error"
              v-if="statusLoading && !statusCancelledImport"
              @click="cancelledImport"
            >
              <v-icon left>mdi-stop</v-icon>
              Остановить импорт
            </v-btn>
      </v-col>
    </v-row>
    <div
      class="my-stepper-import--form-content"
    >
      <div
        class="my-stepper-import--step-three"
        style="text-align: left; "
      >
        <v-row>
          <v-col>
            <span>Всего обработано {{ importResult.length }} из {{ allCountRecordsImport }}:&nbsp;</span>
            <span class="success--text">{{ filterByStatus(['success', 'inserted', 'updated', 'none']).length }} - успешно</span>,&nbsp;
            <span class="warning--text">{{ filterByStatus(['warning']).length }} - успешно с ошибками</span>,&nbsp;
            <span class="error--text">{{ filterByStatus(['error', 'failed']).length }} - ошибка</span>.
          </v-col>
        </v-row>     
        <v-row>
          <v-col>
            <base-table
              id="client-import-result-table"
              :loading="statusLoading"
              :headers="headers"
              :items="importResult"
              item-key="index"
              :options="{
                  sortBy: ['index'],
                }"
              :item-class="(item) => [item.status_color]"
              :is-custom-header="false"
              :word-operations="rowStr"
              loading-text="Результаты загружаются..."
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="my-stepper-import--form-action">
      <div class="app__spacer" />
      <v-btn
        color="secondary"
        @click="back"
      >
        <iconify-icon
          icon="arrow-back"
          width="21"
          style="margin-right: 5px;"
        />
        В начало
      </v-btn>
    </div>
  </div>
</template>

<script>
  import convertor from '@/mixins/convertor'
  import { mapGetters, mapMutations } from 'vuex'

  const statusEnum = {

    success: { text: 'Успешно', color: 'success--text' },
    warning: { text: 'Успешно', color: 'warning--text' },
    error: { text: 'Ошибка', color: 'error--text' },

    inserted: { text: 'Создан', color: 'success--text' },
    updated: { text: 'Обновлен', color: 'success--text' },
    failed: { text: 'Ошибка', color: 'error--text' },

    none: { text: 'Обновлен', color: 'success--text' },

  }

  export default {
    mixins: [convertor],
    props: {
      step: {
        type: Number,
        default: 3,
      },
      excel: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        valid: false,
        loading: false,
        rowStr: ['строка', 'строки', 'строк'],
        headers: [
          { text: '№', align: 'start', value: 'rowNum', width: 1 },
          { text: 'Телефон', align: 'start', value: 'phone', width: 1 },
          { text: 'Id клиента', align: 'start', value: 'account_id', width: 1 },
          { text: 'Статус', align: 'start', value: 'status_text', width: 1 },
          { text: 'Ошибка', align: 'start', value: 'error_text' },
        ],
      }
    },
    computed: {
      ...mapGetters({
        statusLoading: 'crm/client/importStatusLoadingResult',
        statusCancelledImport: 'crm/client/importStatusCancelled',
        allCountRecordsImport: 'crm/client/countRecordsImport',
        importResultStore: 'crm/client/importResult'
      }),
      importResult () {
        if (!this.importResultStore) return []
        return this.importResultStore.map(item => {
          const status = this.getItemStatus(item)
          return Object.assign({}, item, {
            rowNum: item.index + 1,
            error_text: item.errors && item.errors.length > 0 ? item.errors.join(';') : '',
            status: status,
            status_text: statusEnum[status].text,
            status_color: statusEnum[status].color,
          })
        })
      },
    },
    methods: {
      ...mapMutations({
        setImportStatusCancelled: 'crm/client/SET_IMPORT_STATUS_CANCELLED'
      }),
      filterByStatus (statusAny) {
        return this.importResult.filter(item => item.status && statusAny.includes(item.status))
      },
      cancelledImport() {
        this.setImportStatusCancelled(true)
      },
      getItemStatus (item) {
        if (item.status == null) return 'none'
        else if (item.status !== 'failed' && item.errors.length > 0) return 'warning'
        else return item.status
      },
      back () {
        this.$emit('update:step', 1)
      },
    },
    destroyed() {
      this.cancelledImport()
    }
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";
#client-import-result-table {
  tr.error--text {
    td {
      color: $error;
    }
  }
  tr.warning--text {
    td {
      color: $warning;
    }
  }
  tr.success--text {
    td {
      color: $success;
    }
  }
}
</style>
